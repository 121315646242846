import { render, staticRenderFns } from "./ProductWeightingHelp.vue?vue&type=template&id=fa0d1ee8&"
import script from "./ProductWeightingHelp.vue?vue&type=script&lang=js&"
export * from "./ProductWeightingHelp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
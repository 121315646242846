<template>
  <b-row
    align-v="center"
    no-gutters
  >
    <a
      v-b-popover.click.blur.bottom="{ variant:'info', title: helpTitle, content: helpContent }"
      href="#"
      @click.prevent
    >
      <feather-icon
        icon="HelpCircleIcon"
        size="22"
        class="text-primary"
      />
    </a>
  </b-row>
</template>

<script>
import { BRow, VBPopover } from 'bootstrap-vue'

export default {
  components: {
    BRow,
  },
  directives: {
    'b-popover': VBPopover,
  },
  data() {
    return {
      helpTitle: 'Product weighting selection',
      helpContent: 'The choice of product weighting affects all charts and tables in this report. Performance and proportions are calculated with respect to the relevant weighting',
    }
  },
}
</script>
